module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.koomet.fr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"once":false,"threshold":0.2},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Koomet | Agence web Saint-Amand-Montrond - Bourges","short_name":"Agence Web de Saint-Amand-Montrond à Bourges : création site internet, référencement naturel, maintenance | Koomet","description":"Notre agence web basée à Saint-Amand-Montrond vous accompagne dans la création de votre site Internet, son référencement naturel et sa maintenance.","start_url":"/","background_color":"#FFFFFF","theme_color":"#6377E9","display":"standalone","icon":"src/assets/Favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0b8a65c4be790d4b42c5fadc74901a82"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
